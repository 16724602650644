import { BaseLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Card, CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { jobTypeOptions, shiftTypeOptions } from "@src/appV2/PlacementCandidate/constants";
import { CbhIcon } from "@src/appV2/ShiftDiscovery/components/CbhIcon";
import { DotSeparatedList } from "@src/appV2/ShiftDiscovery/components/DotSeparatedList";
import { formatDistance, parseISO } from "date-fns";

import { type PlacementDetail } from "../api/useGetPlacement";
import { getPlacementDetailsPath } from "../paths";
import { PlacementApplicationStatus, PlacementPerk } from "../types/fetchPlacements.schema";
import { formatPayRange } from "../utils";
import { PlacementApplicationStatusChip } from "./PlacementApplicationStatusChip";

interface PlacementCardItemProps {
  placement: PlacementDetail;
  placementCandidateId: string;
  pageIndex: number;
  placementIndex: number;
}

export function PlacementCardItem(props: PlacementCardItemProps) {
  const { placement, placementCandidateId, pageIndex, placementIndex } = props;

  const hasApplied = isDefined(placement.applications) && placement.applications.length > 0;
  const appliedDate = placement.applications?.[0]?.attributes.appliedDate;

  const hasPayRateRange =
    isDefined(placement.payRange) &&
    isDefined(placement.payRange.minPay) &&
    isDefined(placement.payRange.maxPay) &&
    placement.payRange.maxPay.amountInMinorUnits !== placement.payRange.minPay.amountInMinorUnits;
  const visiblePayRate =
    placement.payRange?.maxPay?.amountInMinorUnits ??
    placement.payRange?.minPay?.amountInMinorUnits;

  const shiftTypeAndSpecialPerkElements = [
    ...placement.shiftTypes.map((shiftType) => {
      const shiftTypeOption = shiftTypeOptions.find((option) => option.value === shiftType);
      if (!isDefined(shiftTypeOption)) {
        return null;
      }

      return (
        <Box key={shiftType} component="span">
          <CbhIcon type={shiftTypeOption.icon} size="xSmall" />
        </Box>
      );
    }),
    placement.perks?.includes(PlacementPerk.TWELVE_SHIFTS_AVAILABLE) ? (
      <Text variant="body2" color="text.secondary">
        12h shifts
      </Text>
    ) : null,
  ].filter(Boolean);
  return (
    <Card
      variant="elevation"
      sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
    >
      <CardActionArea
        component={BaseLink}
        to={getPlacementDetailsPath(placement.id, placementCandidateId)}
        onClick={() => {
          logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_VIEWED, {
            placementCandidateId,
            placementDetails: placement,
            page: pageIndex + 1,
            listIndex: placementIndex + 1,
            hasApplied,
            appliedDate,
          });
        }}
      >
        <CardContent>
          <Stack spacing={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Stack spacing={4} flex={1}>
                {hasApplied && isDefined(appliedDate) && (
                  <Box sx={{ paddingBottom: 6 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Chip
                        label={`Applied ${formatDistance(new Date(), parseISO(appliedDate))} ago`}
                        variant="filled"
                        size="small"
                      />
                      {isDefined(placement.applications) &&
                        placement.applications[0]?.attributes.status !==
                          PlacementApplicationStatus.APPLIED && (
                          <PlacementApplicationStatusChip
                            status={placement.applications[0].attributes.status}
                          />
                        )}
                    </Stack>
                  </Box>
                )}
                <Stack direction="column" spacing={1}>
                  <Text
                    semibold
                    variant="body1"
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    {placement.workplace.name}
                  </Text>
                  <Text variant="caption" color="text.secondary">
                    {formatDistanceInMiles(placement.distance)} from home
                  </Text>
                </Stack>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                {isDefined(placement.workerTypes) && (
                  <Chip
                    label={
                      placement.workerTypes.length > 1
                        ? `${placement.workerTypes[0]} + ${placement.workerTypes.length - 1} more`
                        : placement.workerTypes[0]
                    }
                    variant="outlined"
                    size="small"
                    sx={{
                      border: "0.5px solid",
                      borderColor: (theme) => theme.palette.border?.primary,
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Stack spacing={1}>
                <Text variant="body2">
                  {placement.jobTypes
                    .map(
                      (jobType) => jobTypeOptions.find((option) => option.value === jobType)?.label
                    )
                    .join(", ")}
                </Text>

                <DotSeparatedList>{shiftTypeAndSpecialPerkElements}</DotSeparatedList>
              </Stack>

              {visiblePayRate && (
                <Stack
                  alignItems="flex-end"
                  flexWrap="nowrap"
                  flexShrink={0}
                  sx={{
                    padding: 4,
                    border: "0.5px solid",
                    borderColor: (theme) => theme.palette.border?.primary,
                    borderRadius: 4,
                  }}
                >
                  {hasPayRateRange && (
                    <Text variant="caption" color="text.secondary">
                      Pay range
                    </Text>
                  )}
                  {!hasPayRateRange &&
                    placement.payRange?.minPay &&
                    !placement.payRange?.maxPay && (
                      <Text variant="caption" color="text.secondary">
                        From
                      </Text>
                    )}
                  {!hasPayRateRange && placement.payRange?.maxPay && (
                    <Text variant="caption" color="text.secondary">
                      Up to
                    </Text>
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text semibold variant="body2" textAlign="center" sx={{ fontSize: 13 }}>
                      {formatPayRange(placement.payRange, false)}
                    </Text>
                    <Text variant="body2" color="text.secondary">
                      /hr
                    </Text>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
