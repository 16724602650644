import { isDefined } from "@clipboard-health/util-ts";

import { formatCentsAsUsd } from "../lib/Money/utils/currency";
import { type Worker } from "../Worker/api/types";
import { type PlacementDetail } from "./api/useGetPlacement";
import {
  type GetPlacementsForWorkplaceQuery,
  PlacementApplicationStatus,
} from "./types/fetchPlacements.schema";

export const DEFAULT_DISTANCE_FILTER_IN_MILES = 150;

export function formatPayRange(payRange: PlacementDetail["payRange"], showPerHour = true) {
  const minPay = payRange?.minPay?.amountInMinorUnits;
  const maxPay = payRange?.maxPay?.amountInMinorUnits;
  const perHour = showPerHour ? " /hr" : "";

  if (!minPay && !maxPay) {
    return undefined;
  }

  if (minPay && maxPay && minPay !== maxPay) {
    return `${formatCentsAsUsd(minPay)}${perHour} - ${formatCentsAsUsd(maxPay)}${perHour}`;
  }

  return `${formatCentsAsUsd(minPay ?? maxPay ?? 0)}${perHour}`;
}

export function getPayRangeText(payRange: PlacementDetail["payRange"]) {
  if (!payRange?.minPay && !payRange?.maxPay) {
    return "";
  }

  if (
    isDefined(payRange.minPay) &&
    isDefined(payRange.maxPay) &&
    payRange.minPay.amountInMinorUnits !== payRange.maxPay.amountInMinorUnits
  ) {
    return "";
  }

  if (isDefined(payRange.minPay) && !isDefined(payRange.maxPay)) {
    return "From";
  }

  if (isDefined(payRange.maxPay)) {
    return "Up to";
  }

  return "Pay";
}

export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = {
  CNA: "CNA",
  "LVN/LPN": "LVN",
  RN: "RN",
  Caregiver: "CAREGIVER",
  HHA: "HHA",
  CHHA: "CHHA",
};
export function getAvailableWorkerTypesToFilter(workerLicensesData: Worker["licensesData"]) {
  if (!workerLicensesData) {
    return ["none"];
  }

  const qualifications = workerLicensesData.map((license) => license.qualification);
  // eslint-disable-next-line unicorn/prefer-spread
  const uniqueQualifications = Array.from(new Set(qualifications));

  const filteredQualifications = uniqueQualifications.filter((qualification) =>
    Object.values(DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER).includes(qualification)
  );

  return filteredQualifications.length > 0 ? filteredQualifications : ["none"];
}

export function buildPlacementsFilter({
  filter,
  showApplications,
  worker,
  searchByWorkplaceName,
}: {
  filter: GetPlacementsForWorkplaceQuery["filter"] | undefined;
  showApplications: boolean;
  worker: Worker;
  searchByWorkplaceName: string;
}) {
  if (!filter) {
    return undefined;
  }

  return {
    ...(showApplications
      ? { "applications.status": Object.values(PlacementApplicationStatus).join(",") }
      : {}),
    ...filter,
    distanceInMiles:
      isDefined(filter?.distanceInMiles) && filter.distanceInMiles > 0
        ? filter.distanceInMiles
        : DEFAULT_DISTANCE_FILTER_IN_MILES,
    workerTypes: isDefined(filter?.workerTypes)
      ? filter.workerTypes
      : getAvailableWorkerTypesToFilter(worker.licensesData).join(","),
    workplaceName: searchByWorkplaceName || undefined,
  };
}
