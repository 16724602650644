import { Box, Chip } from "@mui/material";
import { type Theme } from "@mui/material/styles";

import {
  PlacementApplicationStatus,
  placementApplicationStatusMap,
} from "../types/fetchPlacements.schema";

interface PlacementApplicationStatusChipProps {
  status: PlacementApplicationStatus;
}

export function PlacementApplicationStatusChip(props: PlacementApplicationStatusChipProps) {
  const { status } = props;

  let chipBackgroundColor: "info" | "success" | "warning";

  switch (status) {
    case PlacementApplicationStatus.APPLIED: {
      chipBackgroundColor = "info";
      break;
    }

    case PlacementApplicationStatus.ACCEPTED: {
      chipBackgroundColor = "info";
      break;
    }

    case PlacementApplicationStatus.INTERVIEW: {
      chipBackgroundColor = "info";
      break;
    }

    case PlacementApplicationStatus.OFFER: {
      chipBackgroundColor = "success";
      break;
    }

    case PlacementApplicationStatus.HIRED: {
      chipBackgroundColor = "success";
      break;
    }

    case PlacementApplicationStatus.REJECTED: {
      chipBackgroundColor = "warning";
      break;
    }

    default: {
      chipBackgroundColor = "info";
      break;
    }
  }

  return (
    <Box>
      <Chip
        variant="filled"
        size="small"
        label={placementApplicationStatusMap[status].label}
        sx={{
          backgroundColor: (theme: Theme) => theme.palette[chipBackgroundColor].main,
          "& .MuiChip-label": {
            color: (theme: Theme) =>
              theme.palette.getContrastText(theme.palette[chipBackgroundColor].main),
          },
        }}
      />
    </Box>
  );
}
