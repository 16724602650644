import { InternalLink, LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { isCbhApiAxiosError } from "@src/appV2/Errors/errorUtilts";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useIsPlacementChatsEnabled } from "@src/appV2/PlacementChats/useIsPlacementChatsEnabled";
import {
  type CreatePlacementChatChannelRequest,
  useCreateChatChannel,
} from "@src/appV2/ShiftBlocks/api/useCreateChatChannel";
import { CbhIcon } from "@src/appV2/ShiftDiscovery/components/CbhIcon";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type QueryObserverResult } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import { useCreatePlacementApplication } from "../api/useCreatePlacementApplication";
import { type PlacementDetail } from "../api/useGetPlacement";
import { getPlacementChatPath } from "../paths";
import { type PlacementResponse } from "../types/fetchPlacements.schema";
import { PlacementApplicationSuccessBottomSheet } from "./PlacementApplicationSuccessBottomSheet";

interface PlacementApplyButtonContainerProps {
  placementDetails: PlacementDetail;
  placementCandidateId: string;
  refetchPlacement: () => Promise<QueryObserverResult<PlacementResponse>>;
}

export function PlacementApplyButtonContainer(props: PlacementApplyButtonContainerProps) {
  const { placementDetails, placementCandidateId, refetchPlacement } = props;
  const { enableHcpChatWithoutApplication } = useIsPlacementChatsEnabled();
  const { showErrorToast, showSuccessToast } = useToast();
  const history = useHistory();
  const worker = useDefinedWorker();
  const { mutateAsync: createPlacementApplication, isLoading: isCreatingPlacementApplication } =
    useCreatePlacementApplication();
  const placementApplicationSuccessModalState = useModalState();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel<CreatePlacementChatChannelRequest>();

  const hasApplied =
    isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0;

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: 4,
          paddingY: 6,
          backgroundColor: (theme) => theme.palette.background.tertiary,
          borderTop: 1,
          borderColor: "divider",
          zIndex: 1200,
        }}
      >
        {isDefined(placementDetails) && isDefined(placementCandidateId) && (
          <Stack spacing={5}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              isLoading={isCreatingPlacementApplication}
              disabled={hasApplied}
              onClick={async () => {
                try {
                  await createPlacementApplication({
                    placementCandidateId,
                    placementId: placementDetails.id,
                  });
                  await refetchPlacement();
                  showSuccessToast(
                    `You've successfully applied to ${placementDetails.workplace.name}! Please check your email for next steps.`
                  );
                  placementApplicationSuccessModalState.openModal();
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATED, {
                    placementCandidateId,
                    placementDetails,
                  });
                } catch (error) {
                  if (
                    isCbhApiAxiosError(error) &&
                    error.response.data.errors.some(
                      (error) =>
                        error.detail ===
                        "The candidate is not licensed for any of this placement's worker types in the facility's state"
                    )
                  ) {
                    showErrorToast(
                      <Text variant="body2" color="inherit">
                        Application failed since you aren&apos;t licensed to work in the
                        facility&apos;s state.{" "}
                        <InternalLink
                          color="inherit"
                          sx={{ textDecorationColor: "inherit" }}
                          to={DeprecatedGlobalAppV1Paths.LICENSE_MANAGER}
                        >
                          Tap here
                        </InternalLink>{" "}
                        to update your licenses.
                      </Text>
                    );
                    return;
                  }

                  showErrorToast("Failed to apply for job.");
                }
              }}
            >
              {hasApplied ? "Applied" : "Apply to this Job"}
            </LoadingButton>
            {enableHcpChatWithoutApplication && (
              <LoadingButton
                fullWidth
                isLoading={isCreatingChatChannel}
                disabled={isCreatingChatChannel}
                variant="outlined"
                size="large"
                color="primary"
                startIcon={isCreatingChatChannel ? undefined : <CbhIcon type="text-bubble" />}
                onClick={async () => {
                  await createChatChannel({
                    facilityId: placementDetails.workplace.id,
                    placementId: placementDetails.id,
                    agentId: worker.userId,
                  });

                  history.push(
                    getPlacementChatPath(placementDetails.id, placementDetails.workplace.id)
                  );
                }}
              >
                Chat with workplace
              </LoadingButton>
            )}
          </Stack>
        )}
      </Box>
      {placementApplicationSuccessModalState.modalIsOpen && (
        <PlacementApplicationSuccessBottomSheet
          modalState={placementApplicationSuccessModalState}
          placementDetails={placementDetails}
        />
      )}
    </>
  );
}
